<template>
  <div class="box">
    <div class="left leftDetail detailCont">
      <navHead />
      <div class="leftTxt" v-if="id == 1">
        ZAWTIKA development project
      </div>
      <div class="leftTxt" v-if="id == 2">
        Qatar offshore oil field development
      </div>
      <div class="leftTxt" v-if="id == 3">
        YAMAL Project
      </div>
      <div class="leftTxt" v-if="id == 4">
        SHELL PENGUINS FPSO
      </div>
      <div class="leftTxt" v-if="id == 5">
        Wind power facilities fabrication
      </div>
      <div class="leftTxt" v-if="id == 6">
        GCGV Olefin facilities Modules fabrication 
      </div>
      <div class="leftTxt" v-if="id == 7">
        LD21-2/16-3 Jacket fabrication project
      </div>
      <div class="leftTxt" v-if="id == 8">
        Lingshui17-2 oil-field development project
      </div>
      <div class="leftTxt" v-if="id == 9">
        RDC’s 2nd Line project
      </div>
      <div class="leftTxt" v-if="id == 10">
        Crystalliser vessel installation & commissioning
      </div>
      <div class="imgBox" v-if="id == 1">
        <img src="../assets/images/detail1.png">
        Client / Thailand oil<br />
        Operation location / Tianjin (China)<br />
        General work / QC<br />
        Date / 2016
      </div>
      <div class="imgBox imgBox2" v-if="id == 2">
        <img src="../assets/images/detail2.png">
        Client / Qatar Petroleum<br />
        Operation location / Doha<br />
        General work / QC, Commissioning service <br />
        Date / 2018-2019
      </div>
      <div class="imgBox imgBox3" v-if="id == 3">
        <img src="../assets/images/detail3.png">
        Client / Yamal Gaz<br />
        Operation location / Tianjin, Shandong (China) <br />
        General work / QC, Commissioning, Painting Inspection <br />
        Date / 2017-2019
      </div>
      <div class="imgBox imgBox4" v-if="id == 4">
        <img src="../assets/images/detail4.png">
        Client / Shell<br />
        Operation location / Qingdao (China) <br />
        General work / QC and NDT<br />
        Date / 2018.8-present
      </div>
      <div class="imgBox imgBox5" v-if="id == 5">
        <img src="../assets/images/detail5.png">
        Client / Vestas<br />
        Operation location / Tianjin (China) <br />
        General work / NDT<br />
        Date / 2018.12-present
      </div>
      <div class="imgBox imgBox6" v-if="id == 6">
        <img src="../assets/images/detail6.png">
        Client / GCGV<br />
        Operation location / Penglai, Yantai, Shandong (China) <br />
        General work / QC and NDT <br />
        Date / 2019.1-2021.3

      </div>
      <div class="imgBox imgBox7" v-if="id == 7">
        <img src="../assets/images/detail7.png">
        Client / COOEC<br />
        Operation location / Tianjin，Qingdao (China) <br />
        General work / NDT<br />
        Date / 2019.4-2020.3

      </div>
      <div class="imgBox imgBox8" v-if="id == 8">
        <img src="../assets/images/detail8.png">
        Client / COOEC<br />
        Operation location / Qingdao (China) <br />
        General work / QC and NDT<br />
        Date / 2019.8-2021.1

      </div>
      <div class="imgBox imgBox9" v-if="id == 9">
        <img src="../assets/images/detail9.png">
        Client / ESCHER (The Netherlands)<br />
        Operation location / Fujian (China) <br />
        General work / QC, Commissioning service<br />
        Date / 2018.12-2020.9

      </div>
      <div class="imgBox imgBox10" v-if="id == 10">
        <img src="../assets/images/detail10.png">
        Client / ESCHER (The Netherlands)<br />
        Operation location / Lianyungang (China) <br />
        General work / QC, Commissioning service<br />
        Date / 2021.1-present

      </div>
      <div class="goBack" @click="goBack" v-if="id">
        <span>Back to previous page</span>
      </div>
    </div>
    
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Partners',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.box{
  height: auto;
  min-width:1300px;
}
.rightUl{
  padding-top: 30px;
}
.ul{
  margin-bottom: 40px;
}
</style>
